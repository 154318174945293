import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useRouteError } from '@remix-run/react';

export const meta = () => {
  return [
    {
      title:
        "Elevate Your Website's Success with AI-Powered Quizzes Redefining Engagement, Loyalty, and Data for Publishers!",
    },
    { tagName: 'link', rel: 'canonical', href: 'https://quizzly.ai' },
    { property: 'og:url', content: 'https://quizzly.ai' },
    {
      property: 'og:description',
      content:
        "Elevate Your Website's Success with AI-Powered Quizzes Redefining Engagement, Loyalty, and Data for Publishers!",
    },
    {
      property: 'og:title',
      content:
        "Elevate Your Website's Success with AI-Powered Quizzes Redefining Engagement, Loyalty, and Data for Publishers!",
    },
    {
      'script:ld+json': {
        '@context': 'https://schema.org',
        '@graph': [
          {
            '@type': 'Organization',
            '@id': 'https://quizzly.ai/#organization',
            name: 'Quizzly',
            url: 'https://quizzly.ai/',
            sameAs: [
              'https://www.facebook.com/Vuukle/',
              'https://www.linkedin.com/company/Vuukle',
              'https://twitter.com/Vuukle',
            ],
            logo: {
              '@type': 'ImageObject',
              '@id': '',
              inLanguage: 'en-US',
              url: 'https://quizzly.ai/#logo',
              contentUrl: 'https://image.vuukle.com/quizzly-favicon1.webp',
              width: '192',
              height: '192',
              caption: 'Quizzly Logo',
            },
            image: {
              '@id': '',
            },
          },
          {
            '@type': 'WebSite',
            '@id': 'https://quizzly.ai/#website',
            url: 'https://quizzly.ai/',
            name: 'Quizzly',
            description:
              "Elevate Your Website's Success with AI-Powered Quizzes Redefining Engagement, Loyalty, and Data for Publishers!",
            publisher: {
              '@id': 'https://quizzly.ai/#organization',
            },
            inLanguage: 'en-US',
          },
          {
            '@type': 'ImageObject',
            '@id': '',
            inLanguage: 'en-US',
            url: 'https://image.vuukle.com/quizzly-favicon1.webp',
            contentUrl: 'https://image.vuukle.com/quizzly-favicon1.webp',
            width: '192',
            height: '192',
          },
          {
            '@type': 'WebPage',
            '@id': 'https://quizzly.ai/#webpage',
            url: 'https://quizzly.ai/',
            name: "Elevate Your Website's Success with AI-Powered Quizzes Redefining Engagement, Loyalty, and Data for Publishers!",
            isPartOf: {
              '@id': 'https://quizzly.ai/#website',
            },
            about: {
              '@id': 'https://quizzly.ai/#organization',
            },
            primaryImageOfPage: {
              '@id': 'https://quizzly.ai/#primaryimage',
            },
            datePublished: '2023-09-19T13:03:22+00:00',
            dateModified: '2023-09-19T13:03:22+00:00',
            description:
              "Elevate Your Website's Success with AI-Powered Quizzes Redefining Engagement, Loyalty, and Data for Publishers!",
            breadcrumb: {
              '@id': 'https://quizzly.ai/#breadcrumb',
            },
            inLanguage: 'en-US',
            potentialAction: [
              {
                '@type': 'ReadAction',
                target: ['https://quizzly.ai/'],
              },
            ],
          },
          {
            '@type': 'BreadcrumbList',
            '@id': 'https://quizzly.ai/#breadcrumb',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Home',
                item: 'https://quizzly.ai/',
              },
            ],
          },
        ],
      },
    },
  ];
};

export function Layout({ children }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="facebook-domain-verification" content="hlipzm9vsj3vb9ph1jacg4arf6acjq" />
        <link rel="icon" type="image/ico" href="/static/images/logo_transparent.ico" />
        <link rel="apple-touch-icon" href="/static/images/logo_apple.png" sizes="192x192" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Quizzly" />
        <meta property="article:publisher" content="" />
        <meta property="article:modified_time" content="" />
        <meta property="og:image" content="https://image.vuukle.com/" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@vuukle" />
        <meta name="theme-color" content="#071E49" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  return <Outlet />;
}
